<script lang="ts" setup>

import { EASY_APP_ROUTES } from '~/constants';
import SettingsModal from '~/components/pro/templates/Modals/SettingsModal.vue';
const { t } = useI18n();
const modal = useModal();

const mobileNavigation = [
  {
    label: t('navigation.home'),
    icon: 'i-heroicons-home-solid',
    to: EASY_APP_ROUTES.home.path
  },
  {
    label: t('navigation.swap'),
    icon: 'i-heroicons-arrow-path-rounded-square-solid',
    to: EASY_APP_ROUTES.swap.path
  },
  {
    label: t('navigation.transactions'),
    icon: 'i-heroicons-arrows-right-left-solid',
    to: EASY_APP_ROUTES.transactions.path
  }
];

const desktopSideNavigation = [
  {
    label: t('navigation.home'),
    icon: 'i-heroicons-home-solid',
    to: EASY_APP_ROUTES.home.path
  },
  {
    label: t('navigation.swap'),
    icon: 'i-heroicons-arrow-path-rounded-square-solid',
    to: EASY_APP_ROUTES.swap.path
  },
  {
    label: t('navigation.receive'),
    icon: 'i-heroicons-arrow-down-tray-solid',
    to: EASY_APP_ROUTES.receive.path
  },
  {
    label: t('navigation.send'),
    icon: 'i-heroicons-arrow-up-tray-solid',
    to: EASY_APP_ROUTES.send.path
  },
  {
    label: t('navigation.transactions'),
    icon: 'i-heroicons-arrows-right-left-solid',
    to: EASY_APP_ROUTES.transactions.path
  }
];

const desktopBottomActions = [
  {
    label: t('navigation.settings'),
    icon: 'i-heroicons-cog-8-tooth-solid',
    click: () => modal.open(SettingsModal, { close: modal.close })
  }
];

</script>
<template>
  <NuxtLayout name="dashboard">
    <template #header>
      <slot name="header" />
    </template>

    <template #desktop-navigation>
      <UVerticalNavigation
        :ui="{
          icon:{
            base:'size-6'
          }
        }"
        :links="desktopSideNavigation"
      />
    </template>
    <template #desktop-actions>
      <UVerticalNavigation
        :links="desktopBottomActions"
        class="w-full"
      />
    </template>
    <template #mobile-navigation>
      <div class="u-bg-main u-border-gray-200 border-t py-4">
        <UNavigationLinks
          :links="mobileNavigation"
          :ui="{
            wrapper:'space-y-0 grid grid-cols-3 text-center overflow-hidden divide-x u-divide-gray-200',
            base:'inline-flex flex-col border-gray-500 focus:text-primary focus-visible:outline-transparent',
            label:'text-xs mx-6 font-semibold',
            icon: {
              base: 'size-6'
            },
          }"
        />
      </div>
    </template>
    <slot />
  </NuxtLayout>
</template>
